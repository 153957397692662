import styles from "./SuccessPage.module.scss";
import successIcon from "../../assets/images/icons/ui/ui-icon-success.svg";
import Container from "../Container/Container";
import Layout from "../Layout/Layout";
import { getCompanyData } from "../../utils";

const SuccessPage = () => {
  const { successContainer, body } = styles;

  return (
    <Layout hideLogo hideSupportButton>
      <Container className={successContainer}>
        <img
          src={getCompanyData().logo}
          alt={`${getCompanyData().companyName} logo`}
          width={160}
        />
        <div className={body}>
          <img src={successIcon} alt="success" />
          <h1>¡Terminaste el registro!</h1>
          <p>
            Procesaremos tu solicitud y recibirás un mail a la brevedad con las
            instrucciones para acceder a tu cuenta.
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default SuccessPage;
