import { jwtDecode, JwtPayload } from "jwt-decode";
import moment from "moment";
import { Gender, GenderInitials } from "../types";
interface OurJwtPayload extends JwtPayload {
  uuid: string;
}
export const getDraftUUIDFromToken = ({ token }: { token: string }) => {
  const decoded: OurJwtPayload = jwtDecode(token);
  const uid = decoded.uuid;

  return uid;
};

export const parseDate = (date: string) =>
  moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");

export const parseGenderFromInitial: Record<GenderInitials, Gender> = {
  M: "MALE",
  F: "FEMALE",
};

export const parseGenderToInitial = (gender: string) => {
  if (gender === "MALE") return "M";
  if (gender === "FEMALE") return "F";
  return "X";
};

export const parseTelephone = (telephone: string): string => {
  // Validate that what we receive is '54' or '+54' followed by 10 more digits
  const regex = /^\+?54\d{10}$/;

  if (regex.test(telephone)) {
    // Remove the '+' if present and return the complete number
    return telephone.replace(/^\+/, "");
  } else {
    // Return an empty string if the format is not valid
    return "";
  }
};

export const capitalizeString = (country: string) =>
  country.charAt(0).toUpperCase() + country.slice(1).toLowerCase();
